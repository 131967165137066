.container{
    background-color: #EFEFF0;
}
:global(.serverApp) .container{
    cursor: none;
}

.bgImageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bgImage{
    height: 100%;
    width: auto;
    object-fit: contain;

}
.rightNavMenu {
    width: calc(20% - 40px);
    position: fixed;
    top: 0;
    right: -25%;
    height: 100vh;
    z-index: 0;
    background-color: var(--primary-background);
    border-left: 1px solid var(--primary-accent);
    padding: 20px 20px 0 20px;
    border-radius: 29px 0 0 0;
    display: flex;
    flex-direction: column;
    min-width: 19%;
    min-height: auto;
    padding-left: 2.1%;
    min-height: auto;
    width: 19%;
    -webkit-font-smoothing: antialiased;
    transition: all 0.3s ease;
}

.menuOn .rightNavMenuNav {
    background-color: var(--secondary-background);
    box-shadow: 0 1px 1px rgba(0,0,0,0.25);
}

.rightNavMenu.navOpen {
    right: 0;
}

.rightNavBtn path, .rightNavBtn2 path {
    fill: #fff;
}

.rightNavBtn2 {
    position: absolute;
    right: 24px;
    bottom: 24px;
    border-radius: 15px 0 0 0;
}

.rightNavBtn2 svg{
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

.rightNavBtn2 path, .rightNavBtn2 line {
    fill: none !important;
    /* stroke: 1px solid # */
}

.rightNavBtn2.navOpen svg {
    transform: rotate(0deg);
   
}
.dropdownHeading{
    width: 100%;
    cursor: pointer;
    font-family: "CerebriSans",sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
    float: left;
    padding: 20px 0;
}
.dropdownChoices{
    background-color: var(--primary-background);
    border-bottom: 1px solid var(--primary);
    
}
.dropdownChoice{
    border: none;
}

.viewMenuItem{
    font-size: 24px;
    margin:12px 0;
    text-align: left;
    cursor: pointer;
    color:var(--primary);
}

.itemsContainer{
    margin-top: 100px;
    width:100%;
    overflow-y:scroll;
    overflow-x: hidden;
    height:calc( 100% - 120px );
    padding-left: 2.1vw;
}
.viewMenuItem:global(.selected){
    color: var(--primary-accent) 
}
.viewMenuItem:hover {
    color: var(--primary-accent) 
}

