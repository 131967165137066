
.rightNavMenu {
    width: calc(20% - 40px);
    position: fixed;
    top: 0;
    right: -28%;
    height: 100vh;
    z-index: 0;
    background-color: var(--primary-background);
    border-left: 1px solid var(--primary-accent);
    padding: 20px 20px 0 20px;
    border-radius: 29px 0 0 0;
    display: flex;
    flex-direction: column;
    min-width: 19%;
    min-height: auto;
    padding-left: 2.1%;
    min-height: auto;
    width: 19%;
    -webkit-font-smoothing: antialiased;
    transition: opacity 0.2s ease;
}

.rightNavMenu.navOpen {
    right: 0;
    padding: 0;
}

.rightNavMenuNav {
    position: fixed;
    top: 20px;
    width: calc(20% - 20px);
    z-index: 1;
    
}


.menuOn .rightNavMenuNav {
    /* background-color: var(--primary-background); */
    height: 100vh;
    /* box-shadow: 0 1px 1px rgba(0,0,0,0.25); */
}

.navBurger {
    position: absolute;
    top: 10px;
    right: 30px;
}

.dropdownHeading, .navHeading {
    width: 100%;
    cursor: pointer;
    font-family: "CerebriSans", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
    float: left;
    padding: 20px 2.1vw;
}

.dropdownHeading path{
    stroke: var(--primary);
}

.menuOn .dropdownHeading {
    background-color: var(--primary-background);
}

.vrLoadingContainer {
    background-color: var(--primary-background);
}

.moduleHeading {
    /* position: absolute; */
  
}

.dropdownHeadingBg {
    /* width: 100%;
    height: 80px;
    float: left;
    position: relative;
    background: red; */
}

.dropdownChoices {
    width: 100%;
    cursor: pointer;
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 10px 0 20px 0;
}

.menuOn .dropdownChoices{
    opacity: 1;
    height: auto;
}

#dropDownChevron {
    width: 32px;
    height: auto;
    padding: 0 10px 0 10px;
    transform: scale(1,-1);
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 7px;
}

.menuOn #dropDownChevron {
    transform: scale(1,-1) rotate(-180deg);
}

.dropdownChoice {
    cursor: pointer;
    float: left;
    clear: left;
    margin: 0px 0 0px 0;
    padding: 20px 2.1vw;
    font-family: "CerebriSans", sans-serif;
    font-size: 24px;
    color: var(--primary);
    font-weight: 400;
    transition: all 0.3s ease;
    opacity: 1;
    width: 100%;
    border-bottom: 0.5px solid var(--primary);
}

.dropdownChoice.choiceOn {
    font-weight: 600;
}

.rightNavBtn, .rightNavBtn2 {
    cursor: pointer;
    /* background-color: #0F3557; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    /* height: 48px;
    width: 48px; */
}

.rightNavBtn path, .rightNavBtn2 path {
    fill: #fff;
}

.rightNavBtn2 {
    position: absolute;
    right: var(--sideIndent);
    bottom: var(--sideIndent);
    border-radius: 15px 0 0 0;
}

.rightNavBtn2 svg{
    transition: all 0.3s ease;
    transform: rotateY(180deg);
}

.rightNavBtn2 path, .rightNavBtn2 line {
    fill: none !important;
    /* stroke: 1px solid # */
}

.rightNavBtn2.navOpen svg {
    transform: rotate(0deg);
   
}


/* Module */

#mapOverlayContainer {
    position: absolute;
    top: 0;
    left: 0;
}

.buttons {
    float: left;
    width: calc(100% - 40px);
    margin-top: 100px;
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding: 20px 20px 50px 20px;
    position: relative;
    z-index: 0;
}



.pngGroupButtons {
    width: 100%;
    float: left;
    transition: opacity 0.1s ease;
    opacity: 1;
}

.buttonRule {
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
    width: 130%;
    float: left;
    border-top: 1px solid var(--primary-accent);
    /* transform: translateX(20%); */
}

.buttons::-webkit-scrollbar { display: none}

.overlayCategory {
    float: left;
    width: calc(100% - 10px);
    height: auto;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
}

.radioButtons {
    float: left;
    width: 100%;
    margin: 0 0 0 0;
}


.frame {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--primary-background);
}

.mapImage, .baseMap, .overlay {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
}

/* .mapVideo::-webkit-media-controls {
    display:block !important;
  } */

.fullFile {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.fullFile.fileOn {
    opacity: 1;
    z-index: 1;
}


.checkCircle, .categoryLabel {
    /* height: 32px; */
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    float: left;
    width: 100%;
    font-family: "CerebriSans", sans-serif;
    font-size: 22px;
    color: var(--primary);
}

.categoryLabel {
    font-weight: 600;
}

.checkCircle {
    padding: 0 0 0 20px;
    width: calc(100% - 20px);
}

.outerCircle {
    fill: none; 
    stroke: var(--primary); 
    transition: all 0.2s ease;
}

.innerCircle {
    fill: none; 
    stroke: none;
    transition: all 0.2s ease;
}

.choiceOn .innerCircle{
    fill: none; 
    stroke: var(--primary-background); 
}

.choiceOn .outerCircle {
    fill: var(--primary); 
    stroke: var(--primary); 
}

.choiceSemiOn .outerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.choiceSemiOn .innerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.radioOn .outerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.radioOn .innerCircle {
    fill: var(--primary); 
    stroke: none; 
}


.radioButtons .categoryLabel {
    margin-bottom: 6px;
}

.radioButtons .categoryLabel svg {
    margin-right: 10px;
    opacity: 0;
}

.mapControlsContainer {
    position: absolute;
    bottom: var(--sideIndent);
    z-index: 4;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapControls {
    /* width: 318px; */
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    background-color: #fff;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
}

.pinBtn {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    width: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
}



.zoomOutBtn, .zoomInBtn {
    padding: 0 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    align-items: center;
    cursor: pointer;
}

.zoomOutBtn.disabled, .zoomInBtn.disabled, .pinBtn.disabled {
    opacity: 0.3;
}

.mapCanvas {
    position: absolute;
    z-index: 2;
    border: 1px solid fuchsia;
}

.marker {
    width: 30px;
    height: auto;
}

.markerOverlay svg, .marker svg {
    width: 100%;
    height: 100%;
  }

  .markerOverlay {
    /* pointer-events:none; */
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 3;
    transition: opacity 2s ease 0.5s, animation 0.3s ease;
  }

  .markerOverlay.markerOn {
    display: inline;
    opacity: 1;
  }


  .markerOverlay svg path{
    animation-play-state: paused;
  }
  
  .markerOverlay.markerOn svg path{
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation-name: dash;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.markerOverlayGroup svg {
    width: 100%;
    height: 100%;
  }

  .markerOverlayGroup {
    /* pointer-events:none; */
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 2;
  }

  .markerOverlayGroup g[class] {
      opacity: 0;
  }

  .markerOverlayGroup.svgReady {
    display: inline;
    opacity: 1;
  }

  .markerOverlayGroup g[class].markerOn {
    opacity: 1;
  }

  .markerOverlayGroup svg g[class] path{
    animation-play-state: paused;
  }
  
  .markerOverlayGroup svg g[class].markerOn path {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation-name: dash;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.markerPopup {
    cursor: pointer;
}

.popup {
    
    /* transition: opacity 0.3s ease; */
}


@keyframes dash {to {stroke-dashoffset: 0;}}



.fullFile object {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.mapContainer {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-background);
}


/* Controller */

.rightNavMenu.controllerNav {
    height: auto;
    right: 0;
    display: inline;
    position: relative;
    width: 100%;
    padding: 0;
    border: none;
    margin: 20px 0 0 0;
}


.controllerNav .buttons {
    margin-top: 0px;
    height: 100%;
    width: 100%;
    padding: 20px 0 0 0;
    position: fixed;
    top: 160px;
}

.controllerNav .navHeading, 
.controllerNav .dropdownChoice {
    padding: 20px 12px;
}

.controllerNav .navHeading {
    height: 20px;
}

.controllerNav .overlayCategory, .controllerNav .checkCircle {
    padding-top: 10px;
    padding-bottom: 10px;
}

.controllerNav .checkCircle div {
    width: calc(100% - 32px);
}


.controllerNav .dropdownHeading {
    padding-top: 5px;
}

.controllerNav .rightNavMenuNav {
    width: 100%;
    /* position: fixed; */
    top: 110px;
}

.controllerNav .moduleHeading, .controllerNav .dropdownChoice  {
    font-size: 18px;
}

.controllerNav .categoryLabel {
    font-size: 16px;
}


.landingContainer {
    width:100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;

}

  .itemsContainer {
    width:100%;
    display: flex;
    flex-wrap: wrap; 
    position: relative;
    align-content: flex-start;
    z-index: 0;
    margin-bottom:0px;
    padding-top: 160px;
    padding-bottom: 60px;
    min-height: calc(100vh - 161px);
    position: absolute;
    top: 0;
    gap: 30px;
  }

  .itemContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width:384px;
    height: 247px;
    cursor: pointer;
}

.itemContainer img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid #878E91;
    border-radius: 4px;
    display: relative;
    transition: all .25s ease-in-out;
}

  .heading{
    position:relative;
    color:var(--primary);
    top:56px;
    font-weight: 600;
    font-size: 28px;
    z-index: 3;
  }


  .letterbox{
    position: relative;
    width:85%;
    margin: 0 auto;
    z-index: 1;
}


.caption{
    color: var(--primary);
    width: 100%;
    text-overflow: ellipsis;
    /* margin-top:14px; */
    text-align: left;
    font-size: 16px;
    transition: all .25s ease-in-out;
    font-weight: 600;
    padding-top: 10px;
    float: left;
}

.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-position: cover;
}

.closeBtnContainer {
    z-index: 4;
 }


:global(.presentationMode) .rightNavMenu{
    top:32%;
    bottom:0;
    height: auto;
}
:global(.presentationMode) .rightNavMenuNav{
    top:calc(32% + 20px);
}



@media screen and (max-width: 1680px) {

    .navHeading {
        font-size: 20px;
    }

    .checkCircle, .categoryLabel {
        font-size: 18px;
    }
    
    /* .mapControls {
        width: 214px;
    } */

    .dropdownHeading, .dropdownChoice {
        font-size: 20px;
    }

    .rightNavMenu {
        min-width: 280px;
    }

    .rightNavMenuNav {
        min-width: 240px;
    }
    
    .controllerNav.rightNavMenu {
        min-width: 100%;
    }
    
    .controllerNav .rightNavMenuNav {
        min-width: 0;
        max-width: 200px;
    }
    
    
    

}

@media screen and (max-width: 1440px) {

    .navBurger {
        top: 4px;
        right: 0;
        left: 84%;
    }

    .itemContainer{
        width:calc(384px*.9);
        height:calc(247px*.9);  
    }

    .navHeading,  .dropdownHeading, .dropdownChoice {
        font-size: 18px;
    }

    .checkCircle, .categoryLabel {
        font-size: 16px;
    }
    
}



@media screen and (max-width: 1200px) {

    .navBurger {
        top: 12px;
        right: 0;
        left: 96%;
    }
    
    .itemContainer{
        width:calc(384px*.8);
        height:calc(247px*.8);  
    }
    
}
