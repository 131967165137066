.heading{
    position:absolute;
    color:#0F3557;
    top:56px;
    font-weight: 600;
    font-size: 28px;

}
.letterbox{
    position: relative;
    width:85%;
    margin : 0 auto;
}

.container{
    background-color:#EFEFF0;
    
}
.scrollContainer{
    display: flex;
    justify-content: center;
    overflow: hidden scroll;
    
}

.landingContainer {
    width:100%;
    min-height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;

}


.itemsContainer {
    width:100%;
    display: flex;
    flex-wrap: wrap; 
    position: relative;
    align-content: flex-start;
    height: calc(100vh - 161px);
    z-index: 0;
    margin-bottom:0px;
    padding-top: 160px;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    gap: 30px;
    
  }

  .thumbnail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width:384px;
    height: 247px;
    cursor: pointer;
}

.thumbnail img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 1px solid #878E91;
    border-radius: 4px;
    display: relative;
    transition: all .25s ease-in-out;
    overflow: hidden;
}

.thumbnail {
    aspect-ratio: 1.78;
}

.caption{
    width: 100%;
    text-overflow: ellipsis;
    /* margin-top:14px; */
    text-align: left;
    font-size: 16px;
    transition: all .25s ease-in-out;
    font-weight: 600;
    padding-top: 10px;
    float: left;
}

// .itemContainer:hover img{
//     opacity: 0.85;   
// }

.itemContainer:hover .caption{ 
    color:var(--primary-accent);
    
    align-content: flex-start;
}


.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-position: cover;
}


.closeBtnContainer {
    z-index: 4;
 }
