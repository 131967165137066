@import './funcs.scss';

.loginContainer{
    margin: scaleValue(38px , 70px)  scaleValue(22px , 32px) 0px 0;
    position: relative;
    
}

:global(.genericLogin) .loginContainer {
    margin: 40px 0;
}

.loginContainer[loading]{
    transition: opacity 0.5s ease-in-out;
    opacity: 0.5;
    pointer-events: none;
}
.loginRow{
    position:relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(20px * var(--scaleiPad));
}


.loginRow :global(input){
    -webkit-appearance: none;
    // background:rgba(255,255,255,0.3) !important;
    background: transparent;
    border-top:0;
    border-left:0;
    border-right:0;
    border-bottom:1px solid rgba(255,255,255,0.5);
    padding:8px;
    font-size: scaleValue(16px, 20px);
    line-height:scaleValue(18px, 24px);
    width:100%;
    color: white;
    flex-wrap: wrap;
    user-select: text;
    -webkit-user-select: text;
    border-radius: 0;
    // border-radius: 0;
}
.loginRow :global(input):focus{
    outline:0;
    
}

.loginRow :global(input::placeholder) {
    color: rgba(255,255,255,0.25)
}

.buttonEnter{
    // color:var(--primary);
    color: #fff;
    font-family: "GothamBold";
    font-weight: 700;
    font-size: scaleValue(16px, 20px);
    margin-bottom:20px ;
    cursor: pointer;
}
.buttonContinue{
    color:var(--primary);
    font-size: scaleValue(16px, 20px);
    cursor: pointer;
    white-space: nowrap;;
}
.buttonForget{
    color:var(--primary);
    font-size: scaleValue(14px, 17px);
    cursor: pointer;
    white-space: nowrap;;
}
.buttonEnter:hover,
.buttonContinue:hover{
    color:var(--primary-accent)
}
.error{
    position: absolute;
    top:100%;
    margin-top:5px;
    margin-bottom:5px;
    pointer-events: none;
    color:red;
    font-size: calc(12px * var(--scaleiPad));
}
.wheelContainer{
    position : absolute;
    top:10%;
        width:100%;
    text-align:center;
}