.mapboxgl-popup {
   z-index: 5;
}

.mapboxgl-popup-content {
    color: var(--primary);
    background: var(--primary-background);
    border-radius: 10px;
    padding: 15px 15px 17px 15px;
    z-index: 5;
 }

 .mapboxgl-popup-content p {
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
 }
 .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: var(--primary-background);
 }


 .markerPopup {
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 4;
  
}

.markerPopup.markerOn {
    opacity: 1;
}

