.rightNavMenu {
    width: calc(20% - 40px);
    position: fixed;
    top: 0;
    right: -25%;
    height: 100vh;
    z-index: 0;
    background-color: var(--primary-background);
    border-left: 1px solid var(--primary-accent);
    padding: 20px 20px 0 20px;
    border-radius: 29px 0 0 0;
    display: flex;
    flex-direction: column;
    min-width: 19%;
    min-height: auto;
    padding-left: 2.1%;
    min-height: auto;
    width: 19%;
    -webkit-font-smoothing: antialiased;
    transition: all 0.3s ease;
}

.rightNavMenu.navOpen {
    right: 0;
}

.rightNavMenuNav {
    position: fixed;
    top: 20px;
    width: calc(20% - 20px);
    z-index: 1;
    
}

.menuOn .rightNavMenuNav {
    background-color: var(--secondary-background);
    box-shadow: 0 1px 1px rgba(0,0,0,0.25);
}

.dropdownHeading {
    width: 100%;
    cursor: pointer;
    font-family: "CerebriSans", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
    float: left;
    padding: 20px 0 20px 0;
}

.dropdownHeading path{
    stroke: var(--primary);
}

.menuOn .dropdownHeading {
    background-color: var(--primary-background);
}

.dropdownChoices {
    width: 100%;
    cursor: pointer;
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 10px 0 20px 0;
}

.menuOn .dropdownChoices{
    opacity: 1;
    height: auto;
}

#dropDownChevron {
    width: 32px;
    height: auto;
    padding: 0 10px 0 10px;
    transform: scale(1,-1);
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 7px;
}

.menuOn #dropDownChevron {
    transform: scale(1,-1) rotate(-180deg);
}

.dropdownChoice {
    cursor: pointer;
    float: left;
    clear: left;
    margin: 0 0 0 0;
    padding: 10px 0 10px 50px;
    font-family: "CerebriSans", sans-serif;
    font-size: 24px;
    color: #0F3557;
    font-weight: 300;
    transition: all 0.3s ease;
    opacity: 1;
}

.dropdownChoice.choiceOn, .dropdownChoice:hover {
    font-weight: 600;
}

.rightNavBtn, .rightNavBtn2 {
    cursor: pointer;
    /* background-color: #0F3557; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 48px;
    width: 48px; */
}

.rightNavBtn path, .rightNavBtn2 path {
    fill: #fff;
}

.rightNavBtn2 {
    position: absolute;
    right: 24px;
    bottom: 24px;
    border-radius: 15px 0 0 0;
}

.rightNavBtn2 svg{
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

.rightNavBtn2 path, .rightNavBtn2 line {
    fill: none !important;
    /* stroke: 1px solid # */
}

.rightNavBtn2.navOpen svg {
    transform: rotate(0deg);
   
}

/* Module */


.buttons {
    float: left;
    width: 100%;
    margin-top: 100px;
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-bottom: 50px;
    position: relative;
    z-index: 0;
}

.buttons::-webkit-scrollbar { display: none}

.overlayCategory {
    float: left;
    width: calc(100% - 10px);
    height: auto;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 1;
}

.radioButtons {
    float: left;
    width: 100%;
    margin: 30px 0 0 0;
}

.frame {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mapImage, .baseMap, .overlay {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
}

.svgOverlay svg{
    /* position: relative; */
    /* object-fit: none; */
    /* background: rgba(202, 44, 146, 0.5); */
    /* position: absolute; */
    width: 100%;
    height: 100%;
}


/* .mapVideo::-webkit-media-controls {
    display:block !important;
  } */

.fullFile {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.fullFile.fileOn {
    opacity: 1;
    z-index: 1;
}


.checkCircle, .categoryLabel {
    /* height: 32px; */
    display: flex;
    align-items: center;
    float: left;
    width: 100%;
    font-family: "CerebriSans", sans-serif;
    font-size: 24px;
    color: var(--primary);
}

.categoryLabel {
    font-weight: 700;
}

.checkCircle {
    padding: 0 0 0 20px;
    width: calc(100% - 20px);
}


.outerCircle {
    fill: none; 
    stroke: var(--primary); 
    transition: all 0.2s ease;
}

.innerCircle {
    fill: none; 
    stroke: none;
    transition: all 0.2s ease;
}

/* .checkCircle:hover .outerCircle {
    fill: #3c4347; 
    stroke: #3c4347; 
}

.checkCircle:hover .innerCircle {
    fill: none; 
    stroke: #fff; 
} */

.choiceOn .innerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.choiceOn .outerCircle {
    fill: var(--primary); 
    stroke: var(--primary); 
}

.choiceSemiOn .outerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.choiceSemiOn .innerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.radioOn .outerCircle {
    fill: none; 
    stroke: var(--primary); 
}

.radioOn .innerCircle {
    fill: var(--primary); 
    stroke: none; 
}

.radioButtons .categoryLabel {
    margin-bottom: 6px;
}

.radioButtons .categoryLabel svg {
    margin-right: 10px;
    opacity: 0;
}

.svgOverlay path{
    /* width: 100%; */
    stroke-dasharray: 1980;
    stroke-dashoffset: 1980;
    /* animation: dash 1.5s linear forwards; */
    /* stroke: red; */
}

@keyframes dash {to {stroke-dashoffset: 0;}}



@media screen and (max-width: 1680px) {

    .checkCircle, .categoryLabel {
        font-size: 16px;
    }
    

}
