@import '../../../system/styles/funcs.scss';

.bg{
    // display: none;
    // opacity: 0;
}
.stackScroller{
    overflow-x: scroll;
    overflow-y: hidden;
}
.stackBG{
    object-fit: cover;
}
.container {
    position: relative;
    height:100%;
    min-width: 100%;
    color:var(--color); 
    display: inline-flex;
    align-content: flex-start;
    justify-content: space-evenly;
    justify-content: flex-start;
    
}
.leftContainer{
    min-height:100%;
    max-height:100%;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    
}
.svgContainer{
    height: 100%;
}

.btnAddPlan{
    // --svg-stroke:var(--secondary);
    // --svg-stroke-hover:var(--primary-accent);
    position:absolute;
    top:0;
    right:0;
    display: flex;
    align-items: center;
    color:var(--primary);
    font-size:16px;
    cursor: pointer;
}
:global(.presentationMode) .btnAddPlan{
    bottom:0;
    top:auto;
}
.btnAddPlan:global(.inactive) :global(.round-btn){
    pointer-events: none ;
    
}
.btnAddPlan:global(.inactive){
    pointer-events: none ;
    opacity: 0.5;
}
.btnAddPlan :global(line){
    stroke:var(--svg-stroke)
}

.btnAddPlan:hover{
    color:var(--primary-accent);
}
.btnAddPlan:hover :global(line){
    stroke:var(--svg-stroke-hover)
}

.btnAddPlan :global(.round-btn){
    margin:24px 24px 24px 12px;
}
.btnMenuContainer{
    // color:var(--color); 
    font-size: 20px;
    cursor: pointer;
    margin: 30px 30px 30px 0px;
    border-bottom: 1px solid var(--secondary);
    min-width: calc(193px);
    padding:6px;
    display: flex;
    align-content: flex-start;
    position: relative;
    color:var(--secondary);
    --svg-stroke: var(--secondary);
}
.btnMenuContainer :global(span){
    display: flex;
    align-items: center;
}
.btnMenuContainer  :global(path){
    stroke:var(--svg-stroke);
}

.dropupContainer{
    color:var(--color); 
    position: absolute;
    bottom:0px;
    pointer-events: none;
    // background-color: rgba(255,255,255,0.85);
    // transition: bottom 0.25s ease-in-out 0.15s, opacity 0.25s ease-in-out ;
    width:100%;
    // opacity: 0;
    background-color: var(--secondary-background);
    left:0;
}
.portalBG{
    cursor: pointer;
}
.dropupScrollContainer{
    max-height: 440px;
    overflow: auto scroll;
}
.dropupScrollContainer:global(.down){
    max-height: 320px;
}

.dropupScrollContainer[count="0"]{
    visibility: hidden;
    pointer-events: none;
}

.dropupHeading{
    background-color: var(--primary-background);
}

.dropupContainer[open]{
//    bottom:100%;
    // opacity: 1;
//    transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out 0.15s;
   opacity:1; 
}

.dropupContainer[open] .dropupScrollContainer{
    pointer-events: all;
}
.btnMenuContainer[open]  svg{
    transform:rotate(180deg);
}

.dropupItem{
    padding:20px 10px;
    cursor: pointer;
    // opacity: 0;
    // transition: opacity 0.15s ease-in-out
}
.dropupItem[active="true"]{
    font-weight: 600;
}
.dropupContainer[open] .dropupItem{
    // opacity: 1;
    pointer-events: all;
    // transition: opacity 0.15s ease-in-out 0.25s
}

.srcSVG{
    flex: 1;
    height:100%;
    width: auto;
    position: relative;
    opacity: 0;
}
.srcSVG :global(svg){
    height: 100%;
    width:auto;
}
.srcSVG :global(g[layer="clicks"]) rect,
.srcSVG :global(g[layer="clicks"]) path{
    cursor: pointer;
}
.planBG{
    position: absolute;
    top:0;
    height: 100%;
    left:0;
    transform:translateX(-50px);
    // opacity: 0;
    width: auto;
    pointer-events: none;
    display: none;
}
.planContainer{
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-right:20px;
    min-width: scaleValue(338px,634px);   
    opacity: 0;
}
.planName{
    font-size: calc(26px + calc(var(--lerpScale) * 5px));
    font-weight: 600;
    margin-top: calc(33px * var(--lerpScale));
    color:var(--primary)
}
.planSub{
    font-size:  24px;
}

.planImageContainer{
    flex: 1;
     height: 100%; 
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    justify-content: start;
    align-items: center;
}

.planImageSubContainer{
    position: relative;
    transform-origin:top left;
    left:0;
    height: 100%;
}


.planMarker{
    position: absolute;
    cursor: pointer;
}

.imgPlan{
    /* display: none; */
    position: absolute;
    left:0;
    top:0;
}


:global(.stack-container)[plans="1"] .imgPlan{
    max-width: fit-content;
}
.planSelectorContainer{
    /* flex: 1; */
    /* height: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    
}
.btnPlanZoom{
    cursor: pointer;
    height:43px;
    width:43px;
    align-self: center;
    --svg-stroke: var(--secondary);
}

.btnPlanZoom :global(line),
.btnPlanZoom :global(circle),
.btnPlanZoom :global(path){
    stroke: var(--svg-stroke);
}

.btnPlanZoom:hover{
    transform: scale(1.15);
    transition: transform 0.25s ease-in-out;
}

.selectorContainer{
    margin-left:150px;   
    position: absolute;
    bottom:0;
}

.zoomPlanContainer{
    background-color: var(--primary-background);
    opacity:0;
}

.zoomPlan{
    object-fit: contain;
}
.zoomPlanBG{
    object-fit: cover;
}

.zoomPlan:global(.zoomed){
    cursor: move;
}
.btnClose{
    position: absolute;
    right:0;
    bottom:0;
   
}
.zoomControls{
    border-radius: scaleValue(19px,21px);;
    height: scaleValue(38px,42px);
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    // width: 121px;
    // margin-right: 12px;
    pointer-events: all;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0 3px 1px rgba(0,0,0,0.1));
    // margin-bottom:calc(12px * var(--scaleiPad)); 
    background-color: var(--round-btn-background-color);
}


.zoomControls svg:hover{
    // transform: scale(1.15);
}

.zoomControls svg{
    // height:67%;
    // width: 50%;
    transition: all 0.1s ease-in-out;
    cursor:pointer;
    padding: 0 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(calc(30px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
}
.zoomControls svg line{
    stroke:var(--svg-stroke) ;
}
.zoomControls svg:hover  line{
    stroke:var(--svg-stroke-hover) ;
}

.zoomControlsContainer{
    width: 100%;
    justify-content: center;
    background-color:transparent;
    position: absolute;
    bottom: var(--sideIndent);
    display: flex;
    align-items: center;
}

.closePopUp:global(.round-btn) {
    position: fixed;
    bottom: var(--sideIndent);
    left: var(--sideIndent);
    margin: 0;
}

.separator {
    height:0;
    border-right: 1px solid #e6e6e6;
    width: 2px;
    height: scaleValue(38px,42px);
    z-index: 1;
}