
.sectionContainer {
    
}

.switchCheckbox1 {
    display: block;
    opacity: 0;
    height: 0;
}

.switchLbl {
    width: 53px;
    height: 32px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    
    background: linear-gradient(146deg, rgb(205, 207, 209) 0%, rgb(108, 110, 112) 100%);
    border-radius: 30px;
    
    transition: background-color 0.4s;
 
  }
  .switchLbl:after {
    left: 0;
    width: 28px;
    height: 28px;
    margin: 1px;
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 14px;
    border:1px solid white
  }
  .switchCheckbox1:checked + .switchLbl {
    background: rgb(64,125,201);
    background: linear-gradient(146deg, rgba(64,125,201,1) 0%, rgba(0,75,166,1) 100%);
    
  }
  .switchCheckbox1:checked + .switchLbl:after {
    left: auto;
    right: 0;
  }
 