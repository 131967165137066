.container{
   display: inline-block;
   position: relative;
   transition: opacity 0.5s ease-in-out;
   opacity:0;
   overflow: hidden;
   top: 0;
   vertical-align: top;
   transform:translate3d(0,0,0);
   white-space: normal;
   font-size: 14px;
   /* scroll-snap-align: both mandatory ; */
}

.container[loaded="true"]{
   opacity: 1;
}
.container[loaded="false"]{
   opacity:0
}

.indexContainer{
   position: absolute;
   height:100%;
   width: 100%;
}


